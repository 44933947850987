body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
